import { userAuth } from "./login";

interface Props {
  countryCode: string;
  phoneNumber: string;
}

export const sendWpOtp = async (data: Props) => {
  try {
    const body = JSON.stringify(data);

    const res = await fetch(
      "https://seahorse-app-69qkl.ondigitalocean.app/send-wp-otp",
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body,
      }
    );

    const result = await res.json();
    if (res.ok) {
      localStorage.setItem("wp-otp-data", JSON.stringify({ id: result.id }));
      return { message: "Sent the whatsapp otp", status: "success" };
    } else return { message: "Error while sending otp", status: "error" };
  } catch (error) {
    return { message: "Something went wrong", status: "error" };
  }
};

export const verifyWpOtp = async ({
  otp,
  id,
  phoneNumber,
  countryCode,
  isMobile,
  isBooking,
}: {
  otp: string;
  id?: string;
  phoneNumber: string;
  countryCode: string;
  isMobile: boolean;
  isBooking?: boolean;
}): Promise<{ status: "success" | "error"; message: string }> => {
  try {
    if (!id) return { message: "Something went wrong", status: "error" };

    const body = JSON.stringify({ otp, id });

    const res = await fetch(
      "https://seahorse-app-69qkl.ondigitalocean.app/verify-wp-otp",
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body,
      }
    );

    const result = await res.json();
    if (res.ok) {
      if (result.status) {
        const userData = await userAuth({
          phoneNumber,
          isMobile,
          isBooking,
          countryCode,
        });

        if (userData && userData?.status === "error")
          return { message: "Error while verifying", status: "error" };
        else return { message: "Verified successfully", status: "success" };
      } else return { message: "Error while verifying", status: "error" };
    } else return { message: "Error while verifying", status: "error" };
  } catch (error) {
    console.log(error, "ERROR");
    return { message: "Something went wrong", status: "error" };
  }
};

export const isWpOtp = async () => {
  const data = localStorage.getItem("wp-otp-data");
  if (!data) return null;
  const parsedData = JSON.parse(data);
  if (!parsedData) return null;
  const id = parsedData.id;
  return id ? id : null;
};
