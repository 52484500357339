import { HeadlineText } from "./texts/headline-text";

interface Props {
  headingText: string;
  mobileHeadingText: string;
  children: React.ReactNode;
}

export const AuthScreenContainer = ({
  headingText,
  mobileHeadingText,
  children,
}: Props) => {
  return (
    <main className="w-screen p-[75px] mobile:p-[50px_20px]">
      <div className="flex flex-col gap-y-[15px]">
        <HeadlineText className="mobile:hidden text-primaryB7 !leading-[40px] mobile:!leading-[30px]">
          {headingText}
        </HeadlineText>
        <HeadlineText className="hidden mobile:block text-primaryB7 !leading-[40px] mobile:!leading-[30px]">
          {mobileHeadingText}
        </HeadlineText>
        {children}
      </div>
    </main>
  );
};
