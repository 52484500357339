import toast from "react-hot-toast";
import { getSecretsData } from "../../../../data/secrets-data";
import OTP91MSGService from "../../../../actions/otp-service";

export const handleGetOtp = async ({
  phoneNumber,
}: {
  phoneNumber: string;
}) => {
  try {
    if (phoneNumber.length !== 10) {
      toast.error("Currently we only support Indian number");
      return;
    }
    const secrets = await getSecretsData();
    if (!secrets) return;
    await OTP91MSGService.sendOtp(`91${phoneNumber}`, secrets);
    toast.success(`OTP has been sent to ${phoneNumber}`);
    return { status: "success" };
  } catch (error) {
    console.log("Error", error);
    toast.error("Something went wrong!");
  }
};
