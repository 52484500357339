import React, { FormEvent } from "react";

interface Props {
  children: React.ReactNode;
  handleSubmit: (event: FormEvent) => void;
}
export const Form = ({ children, handleSubmit }: Props) => {
  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-y-[15px]">
      {children}
    </form>
  );
};
