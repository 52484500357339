import { FaSpinner } from "react-icons/fa";
import { BodyText } from "./common/texts/body-text";
import React from "react";

interface Props {
  isPending: boolean;
  text: string;
  isNotActive: boolean;
}

export const Button = ({ isNotActive, isPending, text }: Props) => {
  return (
    <>
      {isNotActive ? (
        <InActiveButton>{text}</InActiveButton>
      ) : (
        <ActiveButton isPending={isPending}>
          {isPending ? (
            <FaSpinner size={20} className="infinite-rotate" />
          ) : (
            text
          )}
        </ActiveButton>
      )}
    </>
  );
};

const InActiveButton = ({ children }: { children: React.ReactNode }) => {
  return (
    <button
      type="submit"
      className={`cursor-default bg-neutralN4 border border-primaryB2 p-[15px] w-full h-auto mobile:h-[40px] flex items-center justify-center gap-x-2.5`}
    >
      <BodyText className="!text-[14px] text-primaryB2">{children}</BodyText>
    </button>
  );
};

const ActiveButton = ({
  children,
  isPending,
}: {
  children: React.ReactNode;
  isPending: boolean;
}) => {
  return (
    <button
      type="submit"
      className={`${
        isPending && "!cursor-default"
      } bg-primaryB4 border border-primaryB2 p-[15px] w-full h-auto mobile:h-[40px] flex items-center justify-center gap-x-2.5`}
    >
      <BodyText className="!text-[14px] text-neutralN3">{children}</BodyText>
    </button>
  );
};
