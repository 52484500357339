
import { getDoc, doc } from "firebase/firestore";
import { db } from "../lib/connect-firebase";
import { SecretsData } from "../lib/classes/secret-data";

export const getSecretsData = async (): Promise<SecretsData|null> => {
  try {
    const docRef = doc(db, "ls", "UGgWvt5DZ33GWWLKZiFa");
    const document = await getDoc(docRef);

    if (document.exists()) {
      const data = document.data();
      return SecretsData.fromJson(data);
    }else{
      throw Error();
    }
  } catch (error) {
    console.log("ERROR:", error);
  }
  return null;
};
