export class SecretsData {
    phoneNumber: string;
    authToken: string;
    otp: string;
    wpToken: string;
    widgetId: string;
    gptServerBaseUrl: string;  // New field
  
    constructor({
      phoneNumber,
      authToken,
      otp,
      wpToken,
      widgetId,
      gptServerBaseUrl
    }: {
      phoneNumber: string;
      authToken: string;
      otp: string;
      wpToken: string;
      widgetId: string;
      gptServerBaseUrl: string;
    }) {
      this.phoneNumber = phoneNumber;
      this.authToken = authToken;
      this.otp = otp;
      this.wpToken = wpToken;
      this.widgetId = widgetId;
      this.gptServerBaseUrl = gptServerBaseUrl;
    }
  
    static fromJson(json: any): SecretsData {
      return new SecretsData({
        phoneNumber: json.phoneNumber,
        authToken: json.authToken,
        otp: json.otp,
        wpToken: json.wpToken,
        widgetId: json.widgetId,
        gptServerBaseUrl: json.gptServerBaseUrl,  // Default URL if not provided
      });
    }
  }
  