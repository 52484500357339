import { useEffect, useMemo, useState } from "react";
import { UserDataService } from "../../data/user-data";
import { isCountryCode, isPhoneNumber } from "@/lib/validate";

interface Props {
  phoneNumber: string;
  countryCode: string;
}

export const useDebounce = ({ phoneNumber, countryCode }: Props) => {
  const [debouncedValue, setDebouncedValue] = useState("");
  const [saved, setSaved] = useState(false);

  const userDataService = useMemo(() => new UserDataService(), []);

  useEffect(() => {
    const delay = 300;
    const handler = setTimeout(() => {
      setDebouncedValue(phoneNumber);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [phoneNumber]);

  useEffect(() => {
    if (
      debouncedValue &&
      isPhoneNumber({ value: debouncedValue }) &&
      isCountryCode({ countryCode }) &&
      !saved
    ) {
      (async () => {
        const data = localStorage.getItem("bookingData");
        let user;
        if (data) {
          const bookingData = JSON.parse(data);
          const checkInDate = bookingData.checkInDate;
          const checkOutDate = bookingData.checkOutDate;
          const propertyName = bookingData.propertyName;
          user = await userDataService.addDroppedUser({
            checkInDate,
            checkOutDate,
            propertyName,
            countryCode,
            phoneNumber: debouncedValue,
          });
        } else {
          const isUser = await userDataService.getUser({
            phoneNumber: debouncedValue,
          });

          if (!isUser) {
            user = await userDataService.addUser({
              phoneNumber: debouncedValue,
              countryCode,
            });
          }

          if (user) {
            setSaved(true);
          }
        }
      })();
    }
  }, [debouncedValue, countryCode, saved, userDataService]);

  return debouncedValue;
};
