import { SecretsData } from "../lib/classes/secret-data";

export class OTP91MSGService {
  private static otpMsg91Url = "https://control.msg91.com/api/v5/otp";
  private static authKey = "";

  static async sendOtp(
    phone: string,
    secrets: SecretsData
  ): Promise<string | null> {
    this.authKey = secrets.authToken;
    try {
      const response = await fetch(
        `${this.otpMsg91Url}?template_id=${secrets.widgetId}&mobile=91${phone}&authkey=${secrets.authToken}&otp_length=6`,
        {
          method: "POST",
        }
      );

      if (response.ok) {
        const responseBody = await response.json();
        console.log("response body:", responseBody);
        return responseBody["type"];
      } else {
        console.error("Error sending OTP:", response.statusText);
        return null;
      }
    } catch (error) {
      console.error("Error after calling sendOtp:", error);
      return null;
    }
  }

  static async verifyOtp({
    phoneNumber,
    otp,
    secrets,
  }: {
    phoneNumber: string;
    otp: string;
    secrets: SecretsData;
  }): Promise<string | null> {
    console.log("authkey", secrets.authToken);
    try {
      const finalUrl = `${this.otpMsg91Url}/verify?otp=${otp}&mobile=91${phoneNumber}`;
      const response = await fetch(finalUrl, {
        method: "GET",
        headers: {
          authKey: secrets.authToken,
        },
      });

      if (response.ok) {
        const responseBody = await response.json();
        if (responseBody["type"] === "error") {
          return null;
        }
        return responseBody["message"];
      } else {
        console.error("Error verifying OTP:", response.statusText);
        return null;
      }
    } catch (error) {
      console.error("Error during verifyOtp:", error);
      return null;
    }
  }
}

export default OTP91MSGService;
