"use client";

import React, { Suspense, useEffect, useState } from "react";
import { FormContainer } from "./_components/form-container";
import { useRouter, useSearchParams } from "next/navigation";
import { isLoggedIn } from "../../../../actions/auth";
import { AuthScreenContainer } from "@/components/common/auth-screen-container";

const LoginPage = () => {
  return (
    <Suspense>
      <Login />
    </Suspense>
  );
};

const Login = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const searchParams = useSearchParams();
  const router = useRouter();

  const isBooking = searchParams.get("booking") === "true";

  useEffect(() => {
    (async () => {
      const isAuth = await isLoggedIn();
      setIsAuthenticated(isAuth);
    })();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      if (isBooking) {
        router.back();
      } else {
        router.replace("/");
      }
    }
  }, [isAuthenticated, isBooking, router]);

  if (isAuthenticated) {
    return null;
  }

  return (
    <AuthScreenContainer
      mobileHeadingText="Sign in"
      headingText="tell us your contact number"
    >
      <FormContainer isBooking={isBooking} />
    </AuthScreenContainer>
  );
};

export default LoginPage;
