"use client";

import { FormEvent, useReducer, useTransition } from "react";
import toast from "react-hot-toast";
import { Form } from "../../_components/form";
import { handleGetOtp } from "../../_utils";
import { Button } from "@/components/button";
import { useRouter } from "next/navigation";
import { isCountryCode, isPhoneNumber } from "@/lib/validate";
import { useDebounce } from "@/hooks/use-debounce";
import { InputBox } from "@/components/input-box";
import { sendWpOtp } from "../../../../../actions/wp-otp";

interface State {
  countryCode: string;
  phoneNumber: string;
}

// Define the action types
type Action =
  | { type: "COUNTRYCODE"; value: string }
  | { type: "PHONENUMBER"; value: string };

const valueInitials = {
  countryCode: "+91",
  phoneNumber: "",
};

const errorInitials = {
  countryCode: "",
  phoneNumber: "",
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case "COUNTRYCODE":
      return { ...state, countryCode: action.value };
    case "PHONENUMBER":
      return { ...state, phoneNumber: action.value };
    default:
      return state;
  }
};

interface Props {
  isBooking?: boolean;
}

export const FormContainer = ({ isBooking }: Props) => {
  const [values, valuesDispatch] = useReducer(reducer, valueInitials);
  const [errors, errorsDispatch] = useReducer(reducer, errorInitials);
  const [isPending, startTransition] = useTransition();
  const { push } = useRouter();

  const isInternational = values.countryCode !== "+91";

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (isPending) return;

    startTransition(async () => {
      if (!isCountryCode({ countryCode: values.countryCode })) {
        errorsDispatch({
          type: "COUNTRYCODE",
          value: "Please add a valid country code",
        });
        toast.error("Please add a valid country code");
        return;
      }

      if (!isPhoneNumber({ value: values.phoneNumber })) {
        errorsDispatch({
          type: "PHONENUMBER",
          value: "Please enter your phone number",
        });
        toast.error("Please enter your phone number!");
        return;
      }

      try {
        if (!isInternational) {
          const response = await handleGetOtp({
            phoneNumber: values.phoneNumber,
          });
          if (response?.status === "success") {
            if (isBooking)
              push(
                `/verify-otp?countryCode=${values.countryCode}&phoneNumber=${values.phoneNumber}&booking=true`
              );
            else
              push(
                `/verify-otp?countryCode=${values.countryCode}&phoneNumber=${values.phoneNumber}`
              );
          }
        } else {
          const { message, status } = await sendWpOtp({
            phoneNumber: values.phoneNumber,
            countryCode: values.countryCode,
          });

          if (status === "error") toast.error(message);
          else {
            toast.success(message);
            if (isBooking)
              push(
                `/verify-otp?countryCode=${values.countryCode}&phoneNumber=${values.phoneNumber}&booking=true`
              );
            else
              push(
                `/verify-otp?countryCode=${values.countryCode}&phoneNumber=${values.phoneNumber}`
              );
          }
        }
      } catch (error: any) {
        console.log("ERROR", error);
        toast.error(error.message ? error.message : "Something went wrong");
      }
    });
  };

  useDebounce({
    phoneNumber: values.phoneNumber,
    countryCode: values.countryCode,
  });

  return (
    <Form handleSubmit={handleSubmit}>
      <div className="flex gap-x-[15px] w-full">
        <div className="w-[7.5%] mobile:w-[31%]">
          <InputBox
            name="countryCode"
            type="text"
            value={values.countryCode}
            error={errors.countryCode}
            label="Country code"
            setValue={(value: string) =>
              valuesDispatch({ type: "COUNTRYCODE", value })
            }
            setError={(value: string) =>
              errorsDispatch({ type: "COUNTRYCODE", value })
            }
          />
        </div>
        <div className="w-[90%] mobile:w-[65%]">
          <InputBox
            name="phoneNumber"
            type="text"
            value={values.phoneNumber}
            error={errors.phoneNumber}
            label="Phone Number"
            setValue={(value: string) =>
              valuesDispatch({ type: "PHONENUMBER", value })
            }
            setError={(value: string) =>
              errorsDispatch({ type: "PHONENUMBER", value })
            }
          />
        </div>
      </div>
      <Button
        text={isInternational ? "Get Whatsapp Otp" : "Get OTP"}
        isNotActive={
          !isPhoneNumber({ value: values.phoneNumber }) ||
          !isCountryCode({ countryCode: values.countryCode })
        }
        isPending={isPending}
      />
    </Form>
  );
};
