"use client";

import { BookingInputAction } from "@/hooks/use-booking-values";
import { Dispatch } from "react";
import toast from "react-hot-toast";

export const isString = ({ value }: { value?: string }): boolean => {
  if (!value) return false;
  return typeof value === "string" && value.length >= 2;
};

export const isEmail = ({ value }: { value?: string }): boolean => {
  if (!value) return false;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(value);
};

export const isPhoneNumber = ({ value }: { value?: string }): boolean => {
  if (!value) return false;
  if (value.trim().length <= 5) return false;
  return true;
};

export const isAccountNumber = ({ value }: { value?: string }): boolean => {
  if (!value) return false;
  if (value.trim().length > 18) return false;

  return /^\d+$/.test(value.trim());
};

export const isIfSCCode = ({ value }: { value?: string }): boolean => {
  if (!value) return false;
  if (value.trim().length > 11) return false;
  return true;
};

export const isOtp = ({ value }: { value?: string }): boolean => {
  if (!value) return false;
  const otpRegex = /^\d{6}$/;
  return otpRegex.test(value);
};

export const isCountryCode = ({
  countryCode,
}: {
  countryCode: string | undefined;
}): boolean => {
  if (!countryCode) return false;
  if (countryCode[0] !== "+") return false;

  return true;
};

export const validateBooking = ({
  isFullName,
  isCountry,
  isPurpose,
  isCorrectEmail,
  isCorrectPhoneNumber,
  setError,
  isCorrectCountryCode,
}: {
  isFullName: boolean;
  isCountry: boolean;
  isPurpose: boolean;
  isCorrectEmail: boolean;
  isCorrectPhoneNumber: boolean;
  isCorrectCountryCode: boolean;
  setError: Dispatch<BookingInputAction>;
}): boolean => {
  if (!isFullName) {
    toast.error("Please add your full name");
    setError({ type: "FULLNAME", value: "Please add your full name" });
    return false;
  }

  if (!isCountry) {
    toast.error("Please add your country");
    setError({ type: "COUNTRY", value: "Please add your country" });
    return false;
  }

  if (!isCorrectEmail) {
    toast.error("Please add your email");
    setError({ type: "EMAILID", value: "Please add your email" });
    return false;
  }

  if (!isPurpose) {
    toast.error("Please add your purpose");
    setError({ type: "PURPOSE", value: "Please add your purpose" });
    return false;
  }

  if (!isCorrectPhoneNumber) {
    toast.error("Please enter your valid number");
    setError({ type: "PHONENUMBER", value: "Please add your phone number" });
    return false;
  }

  if (!isCorrectCountryCode) {
    toast.error("Please enter your country code");
    setError({ type: "COUNTRYCODE", value: "Please enter your country code" });
    return false;
  }

  return true;
};
